import { Switch } from '@patrianna/core-components'
import { deleteCookie, getCookie, setCookie } from '@patrianna/shared-utils/cookie'
import { useEffect, useState } from 'react'

function PrismicDebugSwitcher() {
  const [isPrismicCookie, setIsPrismicCookie] = useState(false)

  useEffect(() => {
    setIsPrismicCookie(Boolean(getCookie('PRISMIC_KEYS', document.cookie)))
  }, [])

  const showKyeHandler = () => {
    isPrismicCookie ? deleteCookie('PRISMIC_KEYS') : setCookie('PRISMIC_KEYS', '1')
    setTimeout(() => document.location.reload(), 1000)
  }

  return (
    <>
      Prismic debug mode {isPrismicCookie ? 'Enable' : 'Disable'}{' '}
      <Switch checked={isPrismicCookie} onChange={showKyeHandler} color='primary' />
    </>
  )
}

export default PrismicDebugSwitcher
