import cx from 'classnames'

import { useCountryConfig } from 'app/context/country'
import { useAppSelector } from 'store/hooks'
import { getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'

import { LanguageSwitcher } from '../LanguageSwitcher'
import SocialButtons from '../SocialButtons'

import classes from './styles.module.scss'

function MenuFooter() {
  const { socialLinksFeature } = useCountryConfig()
  const isOpen = useAppSelector(getIsMenuOpenedSelector)

  return (
    <>
      <div className={cx(classes.root, { [classes.isMenuOpen]: isOpen })} data-test='menu-footer-links'>
        {socialLinksFeature.enabled && <SocialButtons />}
        <LanguageSwitcher />
      </div>
    </>
  )
}

export default MenuFooter
