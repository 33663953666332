import { useAppSelector } from 'store/hooks'
import { isAppInitializedSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getGoldCurrencySelector, getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'

const GC_LIMIT = 20000
const SC_LIMIT = 10

export const useIsPulsate = () => {
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const goldCurrency = useAppSelector(getGoldCurrencySelector)
  const sweepstakeCurrency = useAppSelector(getSweepstakeCurrencySelector)
  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)

  return appInitialized && (goldCurrency?.amount < GC_LIMIT || (isScEnabled && sweepstakeCurrency?.amount < SC_LIMIT))
}
