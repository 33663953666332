import { useTranslation } from '@patrianna/core-components'
import { useCountdown } from '@patrianna/shared-hooks'
import { MM_SS, getFormattedCountdown } from '@patrianna/shared-utils'
import cx from 'classnames'
import { useMemo, type FC, type ReactElement } from 'react'

import type { ButtonPropsType } from 'components/Button'
import Button from 'components/Button'
import Countdown from 'components/GoldCoinGeneratorButton/Countdown'
import { trackEvent } from 'config/analytic'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { requestGoldCoinsGeneratorAccept } from 'store/modules/gcGenerator/actions'
import {
  getGcGeneratorAccepting,
  getGcGeneratorAmount,
  getGcGeneratorIntervalDate,
  getGcGeneratorLoading,
} from 'store/modules/gcGenerator/selectors'

import classes from './styles.module.scss'

export type GoldCoinGeneratorButtonProps = ButtonPropsType & {
  dataTest?: string
  CustomButton?: FC<ButtonPropsType>
}

function GoldCoinGeneratorButton({
  className,
  CustomButton,
  dataTest,
  ...rest
}: GoldCoinGeneratorButtonProps): ReactElement {
  const accepting = useAppSelector(getGcGeneratorAccepting)
  const isDataLoading = useAppSelector(getGcGeneratorLoading)
  const gcAmount = useAppSelector(getGcGeneratorAmount)
  const eventDate = useAppSelector(getGcGeneratorIntervalDate)
  const { start, end, isActive } = useCountdown(eventDate, { isActiveByDefault: true })
  const { countdown, pendingCountdown } = useMemo(
    () =>
      isActive
        ? getFormattedCountdown({ start, end }, { isOnlyMinutes: true })
        : { countdown: '', pendingCountdown: MM_SS },
    [start, end, isActive]
  )
  const dispatch = useAppDispatch()
  const t = useTranslation()
  const disabled = !!countdown || accepting || isDataLoading
  const handleClick = () => {
    trackEvent('gc_generator_accepted', {
      label: gcAmount,
    })
    dispatch(requestGoldCoinsGeneratorAccept())
  }

  return CustomButton ? (
    <CustomButton
      {...rest}
      data-test={dataTest}
      onClick={handleClick}
      disabled={disabled}
      className={cx(className, { [classes.countdown]: !!countdown })}
    />
  ) : (
    <Button
      {...rest}
      data-test={dataTest}
      onClick={handleClick}
      disabled={disabled}
      className={cx(className, { [classes.countdown]: !!countdown })}
    >
      <Countdown
        label={t('common.unlocks_in')}
        countdown={countdown}
        pendingCountdown={pendingCountdown}
        loading={accepting}
        readyLabel={t('common.get_coins')}
      />
    </Button>
  )
}

export default GoldCoinGeneratorButton
