import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'
import type { ReactElement, ReactNode } from 'react'

import { Link } from 'components/Link'
import { useAppSelector } from 'store/hooks'
import { getActiveCurrencyIdSelector } from 'store/modules/currencies/selectors'

import PromotionsCount from './components/PromotionsCount'
import classes from './styles.module.scss'

type Props = {
  children?: ReactNode
  text?: string
  title?: string
  icon?: string
  onClick?: () => void
  key?: number | string
  isActive?: boolean
  href?: string
  'data-test'?: string
  badge?: string
}

const badgeComponents: Record<string, ReactElement> = {
  'promotions-count': <PromotionsCount />,
}

export default function MenuItemComponent(props: Props) {
  const activeCurrency = useAppSelector(getActiveCurrencyIdSelector)
  const selectedClass = classes[activeCurrency]
  const t = useTranslation()

  const badge = badgeComponents[props?.badge] || null

  const Icon = (
    <img
      className={cx(classes.icon, props.isActive && selectedClass)}
      src={`${process.env.IMG_ORIGIN}/menu/${props.icon}.svg`}
      alt={`${props.icon} menu icon`}
    />
  )

  if (props?.href?.includes('https')) {
    return (
      <a
        data-test={props?.['data-test']}
        onClick={props.onClick}
        className={cx(classes.link, props.isActive && selectedClass)}
        href={props.href}
        target='_blank'
        rel='noreferrer'
      >
        {props.icon && Icon}
        <div>
          <span className={classes.linkTitle}>{t(`common.${props.title}`) || props.title}</span>
          <br />
          {props.text}
        </div>
      </a>
    )
  }
  if (props?.href) {
    return (
      <Link
        href={props.href}
        passHref
        data-test={props?.['data-test']}
        onClick={props.onClick}
        className={cx(classes.link, props.isActive && selectedClass)}
      >
        {props.icon && Icon}
        <div>
          <span className={classes.linkTitle}>{t(`common.${props.title}`) || props.title}</span>
          {badge}
          <br />
          {props.text}
        </div>
      </Link>
    )
  }

  return (
    <button
      data-test={props?.['data-test']}
      onClick={props.onClick}
      className={cx(classes.linkText, classes.link, props.isActive && selectedClass)}
    >
      {props.icon && Icon}
      <div>
        <span className={classes.linkTitle}>{t(`common.${props.title}`) || props.title}</span>
        <br />
        {props.text}
      </div>
    </button>
  )
}
