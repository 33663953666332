'use client'

import { useScrollRestoration } from '@patrianna/shared-hooks'
import type { GameProduct, GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import cx from 'classnames'
import type { ReactNode } from 'react'

import { useAuth } from 'app/context/auth'
import Hidden from 'components/Hidden'
import MaintenanceMessage from 'components/MaintenanceMessage'
import FavoriteButton from 'containers/FavoriteButton'
import MenuDesktop from 'containers/Menu/MenuDesktop'
import MenuMobile from 'containers/Menu/MenuMobile'
import SmoothscrollLoader from 'containers/SmoothscrollLoader'
import { useConsoleWarningMessage } from 'hooks/useConsoleWarningMessage'
import useGetABTestingFlagForQuickPurchase from 'hooks/useGetABTestingFlagForQuickPurchase'
import { useAppSelector } from 'store/hooks'
import { shouldForceVerifyKYCSelector } from 'store/modules/user/selectors'

import Footer from './Footer'
import Header from './Header'
import classes from './Layout.module.scss'

type Props = {
  children: ReactNode
  hideMenu?: boolean
  isHeaderWithLogo?: boolean
  isHeaderWithClose?: boolean
  hideFooter?: boolean
  hasPatternBg?: boolean
  fullWidthContent?: boolean
  isGameInfoPage?: boolean
  gameProduct?: GameProduct | GameProductShort
  availableCategoriesLinks: Record<string, string>[]
}

export default function Layout({
  hideMenu,
  isHeaderWithLogo,
  isHeaderWithClose,
  hideFooter,
  hasPatternBg,
  isGameInfoPage,
  children,
  gameProduct,
  availableCategoriesLinks,
  fullWidthContent,
}: Props) {
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)
  const { isLoggedIn } = useAuth()
  useScrollRestoration()
  useGetABTestingFlagForQuickPurchase()
  useConsoleWarningMessage()

  return (
    <>
      <Header
        favoriteButton={<FavoriteButton gameProduct={gameProduct} />}
        isHeaderWithLogo={isHeaderWithLogo}
        fullWidth={hideMenu}
        isHeaderWithClose={isHeaderWithClose}
      />
      <SmoothscrollLoader />

      {!hideMenu && (
        <div className={cx(classes.menuDesktop, { [classes.disabled]: isForceVerifyKYC })}>
          <Hidden visibleFor='desktop'>
            <MenuDesktop availableCategoriesLinks={availableCategoriesLinks} />
          </Hidden>
        </div>
      )}

      <Hidden visibleFor='mobile'>
        <MenuMobile availableCategoriesLinks={availableCategoriesLinks} />
      </Hidden>

      <main
        className={cx(classes.main, {
          [classes.fullContent]: hideMenu,
          [classes.hasPatternBg]: isLoggedIn && hasPatternBg,
          [classes.gameInfoPage]: isGameInfoPage,
        })}
      >
        {process.env.MAINTENANCE && <MaintenanceMessage />}
        <div
          className={cx({
            ['main-container limited']: !isHeaderWithLogo,
            [classes.fullWidthContent]: fullWidthContent,
          })}
        >
          {children}
        </div>
      </main>
      {!hideFooter && (
        <div className={cx(classes.footer, { [classes.fullContent]: hideMenu })}>
          <Footer isFooterCentered={hideMenu} className={`${isHeaderWithLogo ? '' : 'main-container limited'}`} />
        </div>
      )}
    </>
  )
}
