import cx from 'classnames'

import { useRouter } from 'app/context/navigation'
import type { BackButtonType } from 'components/BackButton'
import BackButton from 'components/BackButton'
import type { Props as LogoProps } from 'containers/LogoLink'
import LogoLink from 'containers/LogoLink'

import classes from './styles.module.scss'

type Props = {
  className?: string
  backProps?: BackButtonType
  logoProps?: LogoProps
}

export default function LogoWithBackButton({ className, backProps, logoProps }: Props & BackButtonType) {
  const { back } = useRouter()

  return (
    <div className={cx(classes.wrapper, className)}>
      <BackButton {...backProps} className={classes.backBtn} handler={backProps?.handler || back} />
      <LogoLink {...logoProps} />
    </div>
  )
}
