import ProgressBar from 'components/ProgressBar'
import { setPercentage } from 'containers/Menu/MenuItems/VIPProgressBar/utils'

import classes from './styles.module.scss'

type Props = {
  percent: string
  currentStep: number
  nextStep?: number
  title: string
  maxLevel?: number
}

function ProgressBarItem({ percent, nextStep, currentStep, title, maxLevel }: Props) {
  return (
    <div className={classes.progressBarItem}>
      <div className={classes.titleHolder}>
        <div className={classes.title}>{title}</div>
        <div className={classes.percent}>{setPercentage(currentStep, percent, maxLevel)}%</div>
      </div>
      <ProgressBar value={percent} currentStep={currentStep} nextStep={nextStep} />
    </div>
  )
}

export default ProgressBarItem
