import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'
import { usePathname } from 'next/navigation'
import { useMemo } from 'react'

import { useAuth } from 'app/context/auth'
import { useRouter } from 'app/context/navigation'
import Button from 'components/Button'
import CashBalance from 'containers/CashBalance'
import GoldCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/GoldCurrency'
import SweepstakeCurrency from 'containers/Layouts/Layout/Header/components/LoggedIn/SweepstakeCurrency'
import LoggedOut from 'containers/Layouts/Layout/Header/components/LoggedOut'
import More from 'containers/Menu/MenuMobile/components/More'
import useOtpConfirmRequired from 'hooks/useOtpConfirmRequired'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openDialog, toggleMenu } from 'store/modules/dialog/actions'
import { getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import { openShopHandler } from 'store/modules/shop/actions'
import { isQuickStartAvailable, shouldForceVerifyKYCSelector } from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'
import { getLocationForEventsByPathname } from 'utils/analytics'

import classes from './MenuMobile.module.scss'

type Props = {
  availableCategoriesLinks: Record<string, string>[]
}

export default function MenuMobile({ availableCategoriesLinks }: Props) {
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const { push } = useRouter()
  const dispatch = useAppDispatch()
  const { isLoggedIn } = useAuth()
  const pathname = usePathname()
  const locationForEvent = useMemo(() => getLocationForEventsByPathname(pathname), [pathname])
  const t = useTranslation()
  const isOtpConfirmRequired = useOtpConfirmRequired()
  const isSCRulesModalAvailable = useAppSelector(isQuickStartAvailable)
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)

  const handleSearch = () => {
    push(ROUTES.SEARCH)

    if (isOpen) {
      dispatch(toggleMenu(false))
    }
  }

  const onClickStoreHandler = () => {
    if (isOtpConfirmRequired) {
      dispatch(openDialog({ modalName: 'PHONE_VERIFICATION_DIALOG' }))
    } else if (isSCRulesModalAvailable) {
      dispatch(openDialog({ modalName: 'CURRENCY_SWITCH_QUICKSTART' }))
    } else {
      dispatch(
        openShopHandler({
          isMobile: true,
          location: locationForEvent,
          routerPush: push,
        })
      )
    }
  }

  return (
    <div
      className={cx(classes.menuMobile, 'mt-hidden-desktop', {
        [classes.isOpen]: isOpen,
        [classes.disabled]: isForceVerifyKYC,
      })}
      color='default'
    >
      {isLoggedIn ? (
        <div className={classes.cashShop}>
          <div className={classes.cash} onClick={onClickStoreHandler}>
            <CashBalance
              hideMoreInfo
              dataAttribute='header'
              mode='headerMode'
              GoldCurrencyComponent={GoldCurrency}
              SweepstakeCurrencyComponent={SweepstakeCurrency}
            />
          </div>

          <Button onClick={onClickStoreHandler} data-test='header-store-btn' className={classes.shopLink}>
            {t('common.store')}
          </Button>
        </div>
      ) : (
        <LoggedOut />
      )}
      <div className={classes.buttonsWrapper}>
        <Button
          variant={'outlined'}
          className={cx('mt-bottom-navigation-action-wrapper', classes.navigationButton)}
          onClick={handleSearch}
        >
          <img className={classes.image} src={`${process.env.IMG_ORIGIN}/icons/search-icon.svg`} alt='Search' />
        </Button>

        <div className={classes.navigationButton}>
          <More route={pathname} availableCategoriesLinks={availableCategoriesLinks} />
        </div>
      </div>
    </div>
  )
}
