import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { getGamesByCategoryCode } from 'utils/loadGameInfoGetInitialProps'

let cachedGames: GameProductShort[] = null

const useTopGames = (isInitFetch?: boolean, count?: number) => {
  const [topGames, setTopGames] = useState<GameProductShort[]>(cachedGames)
  const [isLoading, setIsLoading] = useState(false)

  const fetchTopGames = useCallback(async () => {
    setIsLoading(true)
    if (!topGames) {
      const response = await getGamesByCategoryCode([{ productCategoryCode: 'top' }])
      cachedGames = response?.categories?.[0]?.products || []
      setTopGames(cachedGames)
    }
    setIsLoading(false)
  }, [topGames])

  const result = useMemo(() => {
    return topGames && topGames.slice(0, count ?? topGames.length)
  }, [topGames, count])

  useEffect(() => {
    if (isInitFetch && !topGames) {
      void fetchTopGames()
    }
  }, [fetchTopGames, isInitFetch, topGames])

  return useMemo(
    () => ({
      topGames: result,
      isLoading,
      fetchTopGames,
    }),
    [fetchTopGames, isLoading, result]
  )
}

export default useTopGames
