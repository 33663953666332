import { useAppSelector } from '@patrianna-payments/shared-store/redeem/store/hooks'
import { usePathname } from 'next/navigation'

import { useAuth } from 'app/context/auth'
import { DROP_SELECTED_CATEGORY } from 'config/customEvents'
import LogoLink from 'containers/LogoLink'
import MenuItems from 'containers/Menu/MenuItems'
import useGetGAEventMenuParams from 'containers/Menu/utilityHook'
import { publish } from 'hooks/useDomEvent'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import ROUTES from 'temp/routes.json'

import MenuFooter from '../MenuFooter'

import MenuDesktopSkeleton from './MenuDesktopSkeleton'
import classes from './styles.module.scss'

function MenuDesktop({ availableCategoriesLinks }: { availableCategoriesLinks: Record<string, string>[] }) {
  const { isLoggedIn } = useAuth()
  const pathname = usePathname()
  const { trackGAEventsInMenu } = useGetGAEventMenuParams()
  const appInitialized = useAppSelector(isAppInitializedSelector)

  const onLogoClickHandler = () => {
    trackGAEventsInMenu(pathname, ROUTES.INDEX, 'clicked_on_logo-to-home')
    publish(DROP_SELECTED_CATEGORY)
  }

  return (
    <>
      <div className={classes.logo}>
        <LogoLink className={classes.logoLink} handleOnClick={onLogoClickHandler} />
      </div>

      {appInitialized ? (
        <>
          <MenuItems isLoggedIn={isLoggedIn} availableCategoriesLinks={availableCategoriesLinks} />
          <MenuFooter />
        </>
      ) : (
        <MenuDesktopSkeleton />
      )}
    </>
  )
}

export default MenuDesktop
