import { useTranslation } from '@patrianna/core-components'
import { useEffect } from 'react'

const headerStyles = 'color:red; font-size:32px; background: #eff6ff;'
const textStyles = 'font-size:16px;'

export const useConsoleWarningMessage = () => {
  const t = useTranslation()

  useEffect(() => {
    console.log(`%c${t('common.dev_tools_console_warn_title')}`, headerStyles)
    console.log(`%c${t('common.dev_tools_console_warn_text')}`, textStyles)
  }, [t])
}
