import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'

import { useAppSelector } from 'store/hooks'
import { getGoldCurrencySelector } from 'store/modules/currencies/selectors'

import BalanceAnimation from './BalanceAnimation'
import classes from './styles.module.scss'

function GoldCurrency({ currencyClassName }: { currencyClassName?: string }) {
  const goldCurrency = useAppSelector(getGoldCurrencySelector)
  const t = useTranslation()

  if (goldCurrency) {
    return (
      <div className={cx('mt-typography-caption', classes.currencyText, currencyClassName)}>
        <img src={`${process.env.IMG_ORIGIN}/menu/coin.png?v=5`} className={classes.icon} alt='gold coin icon' />
        <span data-test='gc-balance-value'>
          {t('common.gold_coin_gc')} <BalanceAnimation amount={goldCurrency.amount} />
        </span>
      </div>
    )
  }

  return null
}

export default GoldCurrency
