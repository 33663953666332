import { useMediaQuery } from '@patrianna/shared-hooks'
import type { ReactNode } from 'react'

type Props = {
  visibleFor: 'mobile' | 'desktop'
  children: ReactNode
}

export default function Hidden(props: Props) {
  const isMobile = useMediaQuery('sm')

  if (props.visibleFor === 'mobile' && isMobile) {
    return <>{props.children}</>
  }

  if (props.visibleFor === 'desktop' && !isMobile) {
    return <>{props.children}</>
  }

  return null
}
