import { useCallback } from 'react'

import SpecialOfferButton from 'components/SpecialOfferButton'
import { DROP_SELECTED_CATEGORY } from 'config/customEvents'
import LogoLink from 'containers/LogoLink'
import { publish } from 'hooks/useDomEvent'

import classes from './Header.module.scss'

export default function Header() {
  const onLogoClick = useCallback(() => publish(DROP_SELECTED_CATEGORY), [])

  return (
    <div className={classes.header}>
      <LogoLink className={classes.logo} handleOnClick={onLogoClick} />
      <SpecialOfferButton />
    </div>
  )
}
