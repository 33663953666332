import { useEffect, useRef, useState } from 'react'

import { useAppSelector } from 'store/hooks'
import { getDialogStackSelector } from 'store/modules/dialog/selectors'

export default function usePrevCurrencies(actualAmount: number) {
  const timeoutId = useRef(null)
  const dialogStack = useAppSelector(getDialogStackSelector)
  const [start, setStart] = useState(actualAmount)
  const [end, setEnd] = useState(actualAmount)

  useEffect(() => {
    if (actualAmount > end && !dialogStack.length) {
      timeoutId.current = setTimeout(() => {
        setStart(end)
        setEnd(actualAmount)
      }, 500)
    }
    if (actualAmount < end) {
      setStart(actualAmount)
      setEnd(actualAmount)
    }

    return () => {
      clearTimeout(timeoutId.current)
    }
  }, [actualAmount, dialogStack.length, end])

  return [start, end]
}
