import type { ReactElement } from 'react'
import type { FC } from 'react'

import type { ButtonPropsType } from 'components/Button'
import type { GoldCoinGeneratorButtonProps } from 'components/GoldCoinGeneratorButton'
import GoldCoinGeneratorButton from 'components/GoldCoinGeneratorButton'
import { useAppSelector } from 'store/hooks'
import { getGcGeneratorEnabled } from 'store/modules/gcGenerator/selectors'

type Props = {
  children: ReactElement
  buttonProps?: GoldCoinGeneratorButtonProps
  CustomButton?: FC<ButtonPropsType>
}

function GoldCoinGenerator({ children, buttonProps, CustomButton }: Props): ReactElement | null {
  const enabled = useAppSelector(getGcGeneratorEnabled)

  return (
    <>
      {enabled && buttonProps && <GoldCoinGeneratorButton CustomButton={CustomButton} {...buttonProps} />}
      {!enabled && children}
    </>
  )
}

export default GoldCoinGenerator
