'use client'

import { useRouter } from 'app/context/navigation'

import BackButtonCore from '../BackButton'

export default function BackButton() {
  const { back } = useRouter()

  return <BackButtonCore handler={back} />
}
