import Skeleton from 'app/components/Skeleton'

import classes from './styles.module.scss'

function MenuDesktopSkeleton() {
  return (
    <>
      <Skeleton className={classes.skeleton1} />
      <Skeleton className={classes.skeleton2} />
      <Skeleton className={classes.skeleton3} />
    </>
  )
}

export default MenuDesktopSkeleton
