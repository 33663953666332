import { useTranslation } from '@patrianna/core-components'
import { useMediaQuery } from '@patrianna/shared-hooks'
import cx from 'classnames'
import { usePathname } from 'next/navigation'

import PageTitle from 'components/PageTitle'
import { isSearchPage } from 'utils/route'

import classes from './styles.module.scss'

type Props = {
  className?: string
  titleClassName?: string
}

export default function NoResults({ className, titleClassName }: Props) {
  const t = useTranslation()
  const isMobile = useMediaQuery('xs')
  const pathname = usePathname()

  return (
    <section className={cx(classes.root, className)}>
      {isSearchPage(pathname) ? (
        <PageTitle
          titleText={t('common.results')}
          hideBackButton={!isSearchPage(pathname) || isMobile}
          titleClassName={titleClassName}
        />
      ) : (
        <h6 className='mt-typography-subtitle1'>{t('common.results')}</h6>
      )}

      <p className={classes.description}>{t('common.search_no_matches')}</p>
    </section>
  )
}
