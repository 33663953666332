// documentElement is used as a root element to prevent additional scrolling in mobile Safari
// https://patrianna.atlassian.net/browse/B2SPIN-5175

export const disableScrollForRoot = () => {
  const rootElement = document.documentElement

  if (!rootElement?.classList.contains('disable-scroll')) {
    rootElement?.classList.add('disable-scroll')
  }
}

export const enableScrollForRoot = () => {
  const rootElement = document.documentElement

  if (rootElement?.classList.contains('disable-scroll')) {
    rootElement.classList.remove('disable-scroll')
  }
}
