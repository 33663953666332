import cx from 'classnames'

import BackButton from './BackButton'
import classes from './styles.module.scss'

type Props = {
  titleText: string
  titleClassName?: string
  hideBackButton?: boolean
  dataTest?: string
}

export default function PageTitle(props: Props) {
  return (
    <div className={cx(classes.title, props.titleClassName)} data-test={props.dataTest}>
      {!props.hideBackButton && <BackButton />}
      <h1>{props.titleText}</h1>
    </div>
  )
}
