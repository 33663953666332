import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'
import type { FunctionComponent } from 'react'

import { useAppSelector } from 'store/hooks'
import { isAppInitializedSelector, sweepstakeEnabledSelector } from 'store/modules/appConfig/selectors'
import { getGoldCurrencySelector, getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'

import Skeleton from '../Skeleton'

import classes from './CashBalance.module.scss'

type CashBalanceProps = {
  mode?: string
  reversed?: boolean
  GoldCurrencyComponent?: FunctionComponent<{ currencyClassName?: string }>
  SweepstakeCurrencyComponent?: FunctionComponent<{ hideMoreInfo?: boolean; currencyClassName?: string }>
  dataAttribute: string
  hideMoreInfo?: boolean
  currencyClassName?: string
}

function CashBalance(props: CashBalanceProps) {
  const { GoldCurrencyComponent, SweepstakeCurrencyComponent, hideMoreInfo, currencyClassName } = props
  const t = useTranslation()

  const isScEnabled = useAppSelector(sweepstakeEnabledSelector)
  const appInitialized = useAppSelector(isAppInitializedSelector)
  const goldCurrency = useAppSelector(getGoldCurrencySelector)
  const sweepstakeCurrency = useAppSelector(getSweepstakeCurrencySelector)

  return (
    <Skeleton className={classes.skeleton} condition={appInitialized}>
      {goldCurrency && sweepstakeCurrency && (
        <div className={cx(classes.root, props.mode && props.mode)} data-test={`cash-balance-${props?.dataAttribute}`}>
          <div className={cx(classes.labels, { [classes.reversedText]: props.reversed })}>
            <div className={classes.label}>
              {GoldCurrencyComponent && goldCurrency ? (
                <GoldCurrencyComponent currencyClassName={currencyClassName} />
              ) : (
                t('common.goldCoins')
              )}
            </div>

            {isScEnabled && (
              <div className={cx(classes.label, classes.labelMobile)}>
                {SweepstakeCurrencyComponent && sweepstakeCurrency ? (
                  <SweepstakeCurrencyComponent hideMoreInfo={hideMoreInfo} currencyClassName={currencyClassName} />
                ) : (
                  t('common.sweepstakesCoins')
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </Skeleton>
  )
}

export default CashBalance
