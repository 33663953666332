import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'
import { InformationOutline } from 'mdi-material-ui'

import { useAppDispatch, useAppSelector } from 'store/hooks'
import { getSweepstakeCurrencySelector } from 'store/modules/currencies/selectors'
import { openDialog } from 'store/modules/dialog/actions'

import BalanceAnimation from './BalanceAnimation'
import classes from './styles.module.scss'

type SweepstakeCurrencyProps = {
  hideMoreInfo?: boolean
  currencyClassName?: string
}

export default function SweepstakeCurrency({ hideMoreInfo, currencyClassName }: SweepstakeCurrencyProps) {
  const dispatch = useAppDispatch()
  const sweepstakeCurrency = useAppSelector(getSweepstakeCurrencySelector)
  const t = useTranslation()

  if (!sweepstakeCurrency) {
    return null
  }

  return (
    <div
      className={cx('mt-typography-caption', classes.currencyText, currencyClassName, {
        [classes.retreatForIcon]: !hideMoreInfo,
      })}
    >
      <img src={`${process.env.IMG_ORIGIN}/menu/money.png?v=5`} className={classes.icon} alt='sc icon' />
      <span data-test='sc-balance-value'>
        {t('common.sweepstakes_coins_sc')} <BalanceAnimation amount={sweepstakeCurrency.amount} decimalPlaces={2} />
      </span>

      {!hideMoreInfo && (
        <button
          className={classes.info}
          aria-label={t('common.info')}
          onClick={() => dispatch(openDialog({ modalName: 'INFO_SC_DIALOG' }))}
        >
          <InformationOutline />
        </button>
      )}
    </div>
  )
}
