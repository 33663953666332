import { useTranslation } from '@patrianna/core-components'
import React from 'react'

import GamesSection from 'containers/Layouts/Layout/Header/components/SearchBar/components/GamesSection/GamesSection'
import useTopGames from 'hooks/useTopGames'
import ROUTES from 'temp/routes.json'

type Props = {
  clearSearch: () => void
}

const TopGamesSection = ({ clearSearch }: Props) => {
  const { topGames } = useTopGames(true)
  const t = useTranslation()

  return (
    <GamesSection
      title={t('common.top_games')}
      games={topGames}
      clearSearch={clearSearch}
      limit={6}
      seeMorePath={ROUTES.GAMES_TOP}
    />
  )
}

export default TopGamesSection
