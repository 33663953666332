import type { UseSearchReturnType } from '@patrianna/shared-hooks/useSearch'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import { useGraphyteSearchBase } from '@patrianna-payments/analytics/graphyte/hooks/useGraphyteSearchBase'
import { useEffect, useMemo } from 'react'

import { useAppSelector, useAppDispatch } from 'store/hooks'
import { getAllProducts } from 'store/modules/games/actions'
import { allVisibleGamesSelector, gamesLoadingSelector } from 'store/modules/games/selectors'
import { getIsAdminUserSelector, getUserIdSelector } from 'store/modules/user/selectors'

export interface UseGraphyteGameSearchReturnType extends UseSearchReturnType<GameProduct> {
  userId: string
  searchId: string
}

export const useGraphyteSearch = (searchQuery?: string): UseGraphyteGameSearchReturnType | null => {
  const isAdmin = useAppSelector(getIsAdminUserSelector)
  const userId = useAppSelector(getUserIdSelector)
  const games = useAppSelector(allVisibleGamesSelector)
  const isGamesLoading = useAppSelector(gamesLoadingSelector)
  const dispatch = useAppDispatch()

  const { setSearch, search, isSearching, result, searchId } = useGraphyteSearchBase({
    games,
    isAdmin,
    userId,
    searchQuery,
    minCharsToSearch: 1,
  })
  const shouldLoadGames = useMemo(() => search.length && !games.length, [search, games])

  useEffect(() => {
    if (shouldLoadGames) {
      dispatch(getAllProducts())
    }
  }, [shouldLoadGames])

  return useMemo(
    () => ({
      setSearch,
      result,
      search,
      isSearching: isGamesLoading || isSearching,
      searchId,
      userId,
    }),
    [setSearch, result, search, isSearching, searchId, userId, isGamesLoading]
  )
}
