import type { ReactNode } from 'react'

import { useAppSelector } from 'store/hooks'
import { getIsAdminUserSelector } from 'store/modules/user/selectors'

type Props = {
  children: ReactNode
}

function AdminProvider(props: Props) {
  const isAdmin = useAppSelector(getIsAdminUserSelector)

  if (isAdmin) {
    return <>{props.children}</>
  }

  return null
}

export default AdminProvider
