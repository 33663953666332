import { useMediaQuery } from '@patrianna/shared-hooks'
import { nextTick } from '@patrianna/shared-utils'
import cx from 'classnames'
import { usePathname } from 'next/navigation'

import { useRouter } from 'app/context/navigation'
import Img from 'components/Img'
import useOtpConfirmRequired from 'hooks/useOtpConfirmRequired'
import { useSpecialOffersAmount } from 'hooks/useSpecialOffersAmount'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openShopHandler } from 'store/modules/shop/actions'
import {
  getUserFirstPurchaseSelector,
  shouldForceVerifyKYCSelector,
  showSpecialOffer,
} from 'store/modules/user/selectors'
import ROUTES from 'temp/routes.json'

import classes from './SpecialOfferButton.module.scss'

export type Props = {
  className?: string
}

export default function SpecialOfferButton({ className }: Props) {
  const { push } = useRouter()
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const isShowSpecialOffer = useAppSelector(showSpecialOffer)
  const amount = useSpecialOffersAmount()
  const isOtpConfirmRequired = useOtpConfirmRequired()

  const isMobile = useMediaQuery('xs')
  const isForceVerifyKYC = useAppSelector(shouldForceVerifyKYCSelector)

  const isHome = pathname === ROUTES.INDEX
  const isShowOnRoutes = pathname === ROUTES.LANDING_PAGE || isHome

  const userFirstPurchase = useAppSelector(getUserFirstPurchaseSelector)

  const handleClick = () => {
    // Move to the main thread to avoid blocking click event
    nextTick(() => {
      dispatch(
        openShopHandler({
          isMobile,
          cta: 'gift_icon',
          location: isHome ? 'homepage' : 'other',
          routerPush: push,
        })
      )
    })
  }

  if (isShowSpecialOffer && isShowOnRoutes && !isForceVerifyKYC && !isOtpConfirmRequired) {
    return (
      <div className={cx(classes.root, className, { [classes.animation]: !userFirstPurchase })}>
        <span className={classes.amount}>{amount}</span>
        <button type='button' onClick={handleClick} className={classes.button}>
          <Img
            className={classes.present}
            src={`${process.env.IMG_ORIGIN}/icons/special-offer.png?v=2`}
            width={41}
            height={45}
          />
        </button>
      </div>
    )
  }

  return null
}
