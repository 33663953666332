import { useTranslation } from '@patrianna/core-components'

import { Link } from 'components/Link'
import RichText from 'components/RichText'
import ROUTES from 'temp/routes.json'

import classes from './styles.module.scss'

type Props = {
  onClick?: () => void
}

export default function ReferFriendBlock(props: Props) {
  const t = useTranslation()

  return (
    <div className={classes.root} onClick={props.onClick}>
      <Link aria-label={t('common.refer_a_friend')} href={ROUTES.REFER_FRIEND}>
        <RichText
          field={t('common.sidebar_raf_text')}
          components={{
            strong: ({ children }) => <span className={classes.textGreen}>{children}</span>,
          }}
        />
      </Link>
    </div>
  )
}
