import { useTranslation } from '@patrianna/core-components'
import cx from 'classnames'

import ButtonLink from 'components/ButtonLink'
import useIsAuthPage from 'hooks/useIsAuthPage'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isAppInitializedSelector } from 'store/modules/appConfig/selectors'
import { toggleMenu } from 'store/modules/dialog/actions'
import { getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import ROUTES from 'temp/routes.json'

import classes from './styles.module.scss'

type Props = {
  className?: string
}

function LoggedOut({ className }: Props) {
  const dispatch = useAppDispatch()
  const t = useTranslation()
  const isAuthPage = useIsAuthPage()
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const appInitialized = useAppSelector(isAppInitializedSelector)

  const handleMenuClose = () => {
    if (isOpen) {
      dispatch(toggleMenu(false))
    }
  }

  return (
    !isAuthPage &&
    appInitialized && (
      <div className={cx(classes.buttonsContainer, className)}>
        <ButtonLink
          href={ROUTES.LOGIN}
          className={classes.button}
          color='GC'
          variant='contained'
          data-test='header-login-btn'
          onClick={handleMenuClose}
        >
          {t('common.login')}
        </ButtonLink>

        <ButtonLink
          href={ROUTES.REGISTER}
          className={cx(classes.button, classes.signup)}
          data-test='header-register-btn'
          onClick={handleMenuClose}
        >
          {t('common.join_now')}
        </ButtonLink>
      </div>
    )
  )
}

export default LoggedOut
