'use client'

import { nextTick } from '@patrianna/shared-utils'
import { fetchSavedCards } from '@patrianna-payments/shared-store/payments/store/actions'
import { getPaymentMethodsSelector } from '@patrianna-payments/shared-store/payments/store/selectors'
import { usePathname } from 'next/navigation'
import { useEffect } from 'react'

import { useAuth } from 'app/context/auth'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { isPlayPage } from 'utils/route'

// [FYI]: Getting all payment methods and getting a flag for A/B testing for Quick Purchase
export default function useGetABTestingFlagForQuickPurchase() {
  const pathname = usePathname()
  const dispatch = useAppDispatch()
  const { isLoggedIn } = useAuth()
  const paymentMethods = useAppSelector(getPaymentMethodsSelector)

  const isGamePlayPage = isPlayPage(pathname)

  useEffect(() => {
    if (isGamePlayPage && isLoggedIn && !paymentMethods) {
      nextTick(() => dispatch(fetchSavedCards()))
    }
  }, [isGamePlayPage, isLoggedIn, paymentMethods])
}
