import { BottomNavigationAction, Drawer } from '@patrianna/core-components'
import { useTranslation } from '@patrianna/core-components'
import { restoreScrollPos, saveScrollPos, useMediaQuery } from '@patrianna/shared-hooks'
import { nextTick } from '@patrianna/shared-utils'
import cx from 'classnames'
import { Close } from 'mdi-material-ui'
import type { SyntheticEvent } from 'react'
import { useEffect } from 'react'

import { useAuth } from 'app/context/auth'
import MenuItems from 'containers/Menu/MenuItems'
import useOtpConfirmRequired from 'hooks/useOtpConfirmRequired'
import { useAppDispatch, useAppSelector } from 'store/hooks'
import { openDialog, toggleMenu } from 'store/modules/dialog/actions'
import { getDialogVisibilityByNameSelector, getIsMenuOpenedSelector } from 'store/modules/dialog/selectors'
import { dismissUnreadPromotionsIcon } from 'store/modules/promotions'
import { disableScrollForRoot, enableScrollForRoot } from 'utils/disableScroll'

import MenuFooter from '../../../MenuFooter'

import OpenDrawerIcon from './components/OpenDrawerIcon'
import classes from './More.module.scss'

function More({
  route,
  availableCategoriesLinks,
}: {
  route: string
  availableCategoriesLinks: Record<string, string>[]
}) {
  const dispatch = useAppDispatch()
  const { isLoggedIn } = useAuth()
  const isOpen = useAppSelector(getIsMenuOpenedSelector)
  const isMobile = useMediaQuery('xs')
  const isExtraGoldDialogVisible = useAppSelector((state) =>
    getDialogVisibilityByNameSelector(state, 'EXTRA_GOLD_DIALOG')
  )
  const isShopDialogVisible = useAppSelector((state) => getDialogVisibilityByNameSelector(state, 'SHOP_DIALOG'))
  const t = useTranslation()
  const isOtpConfirmRequired = useOtpConfirmRequired()

  const toggleMenuAction = (open: boolean) => {
    // Move to main thread to avoid INP
    if (isOtpConfirmRequired) {
      dispatch(openDialog({ modalName: 'PHONE_VERIFICATION_DIALOG' }))

      return
    }
    nextTick(() => {
      dispatch(toggleMenu(open))
    })

    if (open) {
      setTimeout(() => {
        saveScrollPos(route)
        disableScrollForRoot()
      }, 200)

      // Move not priority action to the next tick

      nextTick(() => {
        dispatch(dismissUnreadPromotionsIcon())
      })
    } else {
      enableScrollForRoot()
      restoreScrollPos(route)
    }
  }

  const toggleDrawer = (open: boolean) => (event: SyntheticEvent<any, Event> | KeyboardEvent) => {
    if (
      event &&
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' || (event as KeyboardEvent).key === 'Shift')
    ) {
      return
    }

    toggleMenuAction(open)
  }

  useEffect(() => {
    if ((isExtraGoldDialogVisible || (isMobile && isShopDialogVisible)) && isOpen) {
      toggleMenuAction(false)
    }
    // eslint-disable-next-line react-hooks-static-deps/exhaustive-deps
  }, [isMobile, isExtraGoldDialogVisible, isShopDialogVisible, isOpen])

  useEffect(() => {
    if (!isOpen) {
      enableScrollForRoot()
    }
  }, [isOpen])

  return (
    <>
      {!isOpen ? (
        <BottomNavigationAction
          icon={
            <OpenDrawerIcon>
              <img
                className={classes.closeImage}
                src={`${process.env.IMG_ORIGIN}/menu/menu.png`}
                alt={t('common.menu')}
              />
            </OpenDrawerIcon>
          }
          onClick={toggleDrawer(true)}
          className={cx(classes.navigationButton, classes.moreMenuClose)}
        />
      ) : (
        <BottomNavigationAction
          icon={<Close />}
          onClick={toggleDrawer(false)}
          className={cx(classes.navigationButton, classes.moreMenuClose)}
        />
      )}

      <Drawer
        className={classes.root}
        open={isOpen}
        onClose={toggleDrawer(false)}
        modalClassName={classes.drawerModal}
        disableEnforceFocus
        disableBackdrop
      >
        <div className={classes.drawer}>
          <MenuItems
            toggleMenu={toggleMenuAction}
            isMobile
            isLoggedIn={isLoggedIn}
            availableCategoriesLinks={availableCategoriesLinks}
          />
          <MenuFooter />
        </div>
      </Drawer>
    </>
  )
}

export default More
