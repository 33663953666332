import { useCountryConfig } from 'app/context/country'
import { useAppSelector } from 'store/hooks'
import { getTodayBonusSelector } from 'store/modules/bonusesFlow/selectors'
import { getSpecialOfferAmount } from 'store/modules/shop/selectors'

export const useSpecialOffersAmount = () => {
  const specialOfferAmount = useAppSelector(getSpecialOfferAmount)
  const isDailyRewardAvailable = useAppSelector(getTodayBonusSelector)
  const { referFriendFeature } = useCountryConfig()
  const offers = [specialOfferAmount, Boolean(isDailyRewardAvailable), referFriendFeature.enabled]

  return offers.reduce((acc: number, value) => {
    if (typeof value === 'boolean' && value) {
      return acc + 1
    } else if (typeof value === 'number') {
      return acc + value
    } else {
      return acc
    }
  }, 0) as number
}
