import type { GameProductShort } from '@patrianna/shared-patrianna-types/store/GameModule'
import { useMemo } from 'react'

import { Link } from 'components/Link'

import GamesSectionItem from './GamesSectionItem'
import classes from './styles.module.scss'

type Props = {
  title: string
  games: GameProductShort[] | null
  clearSearch: () => void
  seeMorePath?: string
  limit?: number
  trackClick?: (code: string) => void
}

export default function GamesSection({ title, games, clearSearch, seeMorePath, limit, trackClick }: Props) {
  const isSeeMoreShown = seeMorePath && games?.length > limit
  const visibleGames = useMemo(() => {
    return limit ? games?.slice(0, limit) : games
  }, [games, limit])

  return (
    <section className={classes.root}>
      <div className={classes.headerWrapper}>
        <h6 className='mt-typography-subtitle1'>{title}</h6>
        {isSeeMoreShown && (
          <div className={classes.seeMore}>
            <Link href={seeMorePath}>See all</Link>
          </div>
        )}
      </div>
      <div className={classes.list}>
        {visibleGames?.map((game) => (
          <GamesSectionItem trackClick={trackClick} game={game} clearSearch={clearSearch} key={game.code} />
        ))}
      </div>
    </section>
  )
}
