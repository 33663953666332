import { CountUp } from 'use-count-up'

import usePrevCurrencies from './hooks'

export default function BalanceAnimation({ amount, decimalPlaces = 0 }: { amount: number; decimalPlaces?: number }) {
  const [start, end] = usePrevCurrencies(amount)

  return (
    <CountUp
      start={start}
      end={end}
      duration={2}
      isCounting={true}
      autoResetKey={end}
      decimalSeparator={'.'}
      thousandsSeparator={','}
      easing={'linear'}
      decimalPlaces={decimalPlaces}
    />
  )
}
