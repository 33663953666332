import { setCookie } from '@patrianna/shared-utils/cookie'
import { usePathname, useSearchParams } from 'next/navigation'

import { useRouter } from 'app/context/navigation'
import { LANGUAGES, LANGUAGE_COOKIE_NAME } from 'config/languages'
import images from 'temp/images.json'

import classes from './styles.module.scss'

export function LanguageSwitcher() {
  const query = useSearchParams()
  const pathname = usePathname()
  const { push } = useRouter()
  const hideSwitcher = LANGUAGES.length <= 1

  function handleChangeLanguage(lang: string) {
    setCookie(LANGUAGE_COOKIE_NAME, lang)
    push(`${pathname}?${query}`, null, lang)
  }

  if (hideSwitcher) {
    return null
  }

  return (
    <div className={classes['language-switcher']}>
      {LANGUAGES &&
        LANGUAGES.map((language) => {
          return (
            <div
              key={language}
              onClick={() => {
                handleChangeLanguage(language)
              }}
              className={classes['language-link']}
            >
              <img
                src={`${images.bucket}/spinblitz-images/country-flags/${language}.svg`}
                alt={language}
                width={30}
                height={20}
                className={classes['language-flag-icon']}
              />
            </div>
          )
        })}
    </div>
  )
}
